import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

const AreaTravel = React.lazy(() => import("../view/area_travel/AreaTravel"));
const AreaTravelDetail = React.lazy(() => import("../view/area_travel/AreaTravelDetail"));

function AreaTravelRoutes() {
  return (
    <Routes>
      <Route path="/area_travel" element={<AreaTravel />} />
      <Route path="/area_travel/:areaId" element={<AreaTravelDetail/>} />
    </Routes>
  );
}

export default AreaTravelRoutes;
